body {
    margin: 20;
    font-family: "メイリオ"
      ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: "メイリオ";
  }

  div.rsc-cs {
    justify-content: left;
  }

  div.rsc-header {
    align-items: center;
    background: #EF6C00;
    color: #fff;
    display: flex;
    fill: #fff;
    height: 56px;
    justify-content: space-between;
    padding: 0 10px;
  }

  h2.rsc-header-title {
    margin: 0;
    font-size: 1em
  }
  
  a.rsc-header-close-button {
    cursor: pointer;
  }

  div.contact-header {
    align-items: center;
    background: #ef6c00a9;
    font-size: 14px;
    color: #fff;
    display: flex;
    fill: #fff;
    height: 25px;
    justify-content: left;
    padding: 0 10px;
  }

  a.contact-link {
    color: #fff;
  }

  div.rsc-content {
    height: calc(100% - 140px);
  }